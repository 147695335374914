<template>
  <header class="header" :class="{ notmainactive: notMainpage, headActive: isHeader }">
    <div class="head-box" :class="isMenu ? '' : 'active'">
      <div class="head-right">
        <div class="head-nav-menu visible-sm">
          <div v-if="isMenu">
            <img src="@/assets/common/icon_menu.png" class="icon_menu" @click="goViewMenu" alt="" />
          </div>
          <img v-else src="@/assets/common/icon_close.png" class="icon_close" @click="gocloseMenu" alt="" />
          <!-- <div class="icon-hamburger head-nav-open ">
          <div class="inner">
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </div>
        </div>
        <div class="head-nav-close"></div> -->
        </div>
        <div class="nav-list hidden-xs">
          <div class="item" :class="{ active: ['home'].includes(name) }" title="主页" @click="onTabbar('home')">
            主页
          </div>
          <!-- <div class="item" :class="{ active: ['aboutProduct'].includes(name) }" title="产品介绍" @click="onTabbar('aboutProduct')">
            产品介绍
          </div>
          <div class="item" :class="{ active: ['solution'].includes(name) }" title="解决方案" @click="onTabbar('solution')">
            解决方案
          </div> -->
          <div class="item" :class="{ active: ['aboutUs'].includes(name) }" title="关于我们" @click="onTabbar('aboutUs')">
            关于我们
          </div>
          <div class="item" :class="{ active: ['joinUs'].includes(name) }" title="加入我们" @click="onTabbar('joinUs')">
            加入我们
          </div>
          <router-link to="/goPlay" class="item" target="_blank">去玩一下</router-link>
          <router-link to="/goUpload" class="item" target="_blank">剧本上传</router-link>
        </div>
      </div>
      <div class="head-left-box" @click="gohome">
        <img src="@/assets/common/logo_2.png" class="head-left head-left1 " alt="" />
        <img src="@/assets/common/logo_1.png" class="head-left head-left2 " alt="" />
      </div>
    </div>
    <popup-nav ref="popNav" :Menu.sync="isMenu" />
  </header>
</template>
<script>
import popupNav from './popup_nav.vue';
export default {
  components: {
    popupNav
  },
  data() {
    return {
      name: '',
      notMainpage: false,
      isHeader: false,
      isMenu: true
    };
  },
  watch: {
    $route(val) {
      this.name = val.name;
    }
  },
  created() {
    this.name = this.$route.name;
    if (this.name != 'home' && this.name != 'aboutProduct' && this.name != 'joinUs' && this.name != 'aboutUs') {
      this.notMainpage = true;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    onTabbar(pagename) {
      this.$router.push({ name: pagename });
    },
    handleScroll() {
      // const height = document.documentElement.clientHeight
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 400) {
        this.isHeader = true;
      } else {
        this.isHeader = false;
      }
    },
    gohome() {
      this.$router.push('/');
      this.$refs.popNav.hide();
      this.isMenu = true;
    },
    goViewMenu() {
      this.isMenu = false;
      this.$refs.popNav.show();
    },
    gocloseMenu() {
      this.isMenu = true;
      this.$refs.popNav.hide();
    }
  }
};
</script>
<style lang="less" scoped>
.header {
  .head-box {
    padding: 47px 99px;
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    overflow: hidden;
    .head-left-box {
      float: left;
      .head-left {
        max-width: 100%;
        height: 50px;
      }
    }

    .head-right {
      float: right;
      height: 21px;
      .head-nav-menu {
        margin-top: 0.1rem;
        display: none;
        width: 0.24rem;
        height: 0.22rem;
        .icon-hamburger {
          width: 100%;
          overflow: hidden;
          text-align: center;
          -webkit-overflow-scroll: touch;
          .line {
            width: 100%;
            height: 3px;
            display: block;
            background-color: #fff;
            transition: all 0.3s;
            border-radius: 0.2rem;
            &:nth-child(2) {
              margin: 0.1rem 0;
            }
          }
          &.active {
            .line:nth-child(1) {
              -webkit-transform: translateY(8px) rotate(45deg);
              transform: translateY(8px) rotate(45deg);
            }
            .line:nth-child(3) {
              -webkit-transform: translateY(-10px) rotate(-45deg);
              transform: translateY(-10px) rotate(-45deg);
            }
          }
        }
        .head-nav-close {
          width: 0.35rem;
          height: 0.3rem;
          display: none;
          position: absolute;
          right: -1px;
          top: 10px;
          z-index: 10;
          -webkit-overflow-scroll: touch;
        }
      }
      .nav-list {
        display: flex;
        justify-items: center;
        justify-content: right;
        .item {
          margin-left: 4em;
          position: relative;
          font-size: 16px;
          font-weight: bold;
          color: #f5f5f7;
          opacity: 0.8;
          display: block;
          cursor: pointer;
          &:hover {
            opacity: 1;
          }
          &:after {
            content: '';
            margin: auto;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 2px;
            background: #ffffff;
            opacity: 0;
            border-radius: 1px;
            transition: all 0.6s linear;
          }
          &.active {
            &:after {
              opacity: 0.5;
            }
            .icon {
              display: none;
              &.active {
                display: block;
              }
            }
          }
          .icon {
            height: 40px;
            display: block;
            &.other {
              display: none;
            }
            &.active {
              display: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {
  .header {
    .head-box {
      .head-left1 {
        display: block;
      }
      .head-left2 {
        display: none;
      }
    }
    &.notmainactive {
      .head-left1 {
        display: none;
      }
      .head-left2 {
        display: block;
      }
    }
  }
}

.headActive {
  transition: all 0.3s;
  .head-left1 {
    display: none !important;
  }
  .head-left2 {
    display: block !important;
  }
  .head-right {
    .nav-list {
      .item {
        color: #333 !important;
        &::after {
          background: #333 !important;
        }
        .icon {
          display: none;
          &.other {
            display: block;
          }
        }
        &.active {
          display: block;
          .icon {
            &.other {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .header {
    .head-box {
      .head-right {
        float: left;
      }
      .head-left-box {
        float: right;
      }
    }
  }
}
.notmainactive {
  .head-left1 {
    display: none;
  }
  .head-left2 {
    display: block;
  }
  .head-right {
    .nav-list {
      .item {
        color: #333 !important;
        &::after {
          background: #333;
        }
        .icon {
          display: none;
          &.other {
            display: block;
          }
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    .head-box {
      position: relative;
    }
    .head-left2 {
      display: none;
    }
  }
}

.usActive,
.contactActive {
  .head-box {
    background: #efbcae !important;
    padding: 15px 99px;
    .head-right {
      .nav-list {
        line-height: 50px;
      }
    }
  }
  @media screen and (max-width: 751px) {
    .header {
      .head-box {
        padding: 0.2rem 0.2rem 0;
        position: relative;
        &.active {
          background: transparent !important;
        }
        .head-left {
          height: 0.4rem;
        }
        .head-left2 {
          display: block;
        }
        .head-left1 {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1661px) {
  .header {
    .head-box {
      padding: 17px 59px;
      .head-right {
        .nav-list {
          .item {
            margin-left: 3em;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1046px) {
  .header {
    .head-box {
      zoom: 0.7;
    }
  }
}
@media screen and (max-width: 991px) {
  .header {
    .head-box {
      padding: 0.3rem;

      .head-left1 {
        display: none;
      }
      .head-left2 {
        display: block;
      }
      &.active {
        .head-left2 {
          display: none !important;
        }
        .head-left1 {
          display: block !important;
        }
      }
      .head-right {
        height: 0.55rem;
        .icon_menu {
          width: 100%;
        }
        .icon_close {
          width: 100%;
        }
      }
    }
  }
}
@media screen and (max-width: 751px) {
  .header {
    .head-box {
      .head-left {
        height: 0.6rem;
      }
    }
  }
}
</style>
