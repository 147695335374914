<template>
  <div :class="isShow ? 'active' : ''" class="popup-nav">
    <div class="main-group">
      <p @click="gohome">首页</p>
      <p @click="goAboutus">关于我们</p>
      <p @click="goJoinUs">加入我们</p>
    </div>
    <div class="account">
      <div class="account-box">
        <img class="code" src="@/assets/home/qrcode1.jpg" alt="" />
        <div></div>
        <div class="code-txt">官方公众号</div>
      </div>
      <div class="account-box">
        <img src="@/assets/home/qrcode2.png" class="code" alt="" />
        <div class="code-txt">安卓包</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShow: false
    };
  },
  methods: {
    show() {
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    gohome() {
      this.$router.push('/');
      this.hide();
      this.$emit('update:Menu', true);
    },
    goAboutus() {
      this.$router.push('/aboutUs');
      this.hide();
      this.$emit('update:Menu', true);
    },
    goJoinUs() {
      this.$router.push('/joinUs');
      this.hide();
      this.$emit('update:Menu', true);
    }
  }
};
</script>
<style lang="less" scoped>
.popup-nav {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.9);
  transition: all 0.3s linear 0s;
  opacity: 0;
  transform: translateY(-100%);
  &.active {
    opacity: 1;
    transform: translateY(0);
  }
  .main-group {
    width: 100%;
    text-align: center;
    font-size: 0.22rem;
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0.44rem;
    font-weight: 400;
    p {
      margin-bottom: 0.4rem;
      opacity: 1;
      pointer-events: all;
      &:nth-child(1) {
        transition: opacity 0.25s linear 0.1s;
      }
      &:nth-child(2) {
        transition: opacity 0.25s linear 0.2s;
      }
      &:nth-child(3) {
        transition: opacity 0.25s linear 0.4s;
      }
    }
  }
  .account {
    margin: auto;
    position: absolute;
    bottom: 0.3rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .account-box {
      text-align: center;
      margin: 0 10px;
    }
    .code {
      width: 0.44rem;
      height: 0.44rem;
    }
    .code-txt {
      margin-top: 0.1rem;
      font-size: 0.16rem;
      font-weight: 400;
      color: #cccccc;
      line-height: 0.22rem;
      text-align: center;
    }
  }
}
</style>
