con<template>
  <footer class="footer">
    <div class="foot-top">
      <router-link to="/privacy-policy">隐私政策</router-link> <span class="line">|</span>
      <router-link to="/user-agreement">用户协议</router-link>
    </div>
    <div class="caseno">
      <div class="keep-record">
        <a href="https://beian.miit.gov.cn/#/" target="_blank">浙ICP备 2020038177号-1</a>
        <span>&nbsp;&nbsp;Copyright <i class="en">@2020-2021 </i> </span>
        <span class="company">杭州小楼东科技有限公司</span>
      </div>
      <div class="caseno-txt">地址：浙江省杭州市西湖区马塍路36号火炬高新科技园1号楼</div>
      <div class="caseno-txt">
        <a href="http://www.hangzhoujubao.com/" target="_blank">杭州市违法不良信息举报专区</a> |
        <a href="https://www.12377.cn/" target="_blank">网上有害信息举报专区</a>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.footer {
  padding: 30px 0;
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: center;
  color: #333;
  .foot-top {
    font-size: 18px;
    line-height: 24px;
    .line {
      margin: 0 30px;
    }
  }
  .caseno {
    margin-top: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #999999;
    .caseno-txt {
      margin-top: 10px;
    }
  }
}
@media screen and (max-width: 1441px) {
  .footer {
    // padding: 15px 0;
    .foot-top {
      font-size: 16px;
    }
    .caseno {
      margin-top: 5px;
      font-size: 12px;
      .caseno-txt {
        margin-top: 5px;
      }
    }
  }
}
@media screen and (max-width: 1250px) and (min-width: 1150px) {
  .footer {
    zoom: 0.8;
  }
}
@media screen and (max-width: 991px) {
  .footer {
    .caseno {
      .keep-record {
        margin: auto;
        .company {
          display: block;
        }
      }
      .caseno-txt {
        display: none;
      }
    }
  }
}
@media screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .footer {
    padding: 0.4rem 0 0.3rem !important;
  }
}
@media screen and (max-width: 751px) {
  .footer {
    padding: 0.2rem 0 0.2rem;

    .foot-top {
      margin-bottom: 0.05rem;
      font-size: 0.12rem;
      line-height: 0.17rem;
      color: #999999;
      .line {
        margin: 0 0.09rem;
        color: #cccccc;
      }
    }
    .caseno {
      color: #cccccc;
      line-height: 0.2rem;
      font-size: 0.1rem;
    }
  }
  .homeActive {
    .footer {
      position: absolute;
      bottom: 0;
    }
  }
}
</style>
